




























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ConfirmationDialog extends Vue {
  @Prop() value!: boolean;
  @Prop() loading!: boolean;
  @Prop() title!: string;
  @Prop() text?: string;
  @Prop() btnText?: string;

  get visible(): boolean {
    return this.value;
  }

  set visible(value: boolean) {
    this.$emit("input", value);
  }
}
